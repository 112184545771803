@value custom: "../../../styles/colors.css";
@value primary from custom;


.contiene_tabla{
    margin: 3rem 0 ;
    border-top: 1px solid #ccc;
    justify-content: center;
    overflow: auto;
    width: 100%
}
.mostrar_nuevo_reclamo{
    display: flex;
    justify-content: space-between;
    width: 90vw;
}
.mostrar_nuevo_reclamo>div>p{
    padding-bottom: 0;
    margin-bottom: 0;
    place-self: center;
}
.mostrar{
    display: flex;
}
.mostrar p{
    margin-right: 1rem;
    align-self: center;
}
.mostrar>select{
    background-color: #f1f3fe;
    box-shadow: inset 1px 1px 3px #ccc;
}
.mostrar *{
    width: 9ch;
}
.dropdown *{
    color: whitesmoke;
    background-color:primary;
    border-radius: 10px;
    border-color: primary;
}
.dropdown button:hover{
    /*background-color: #4d59cb;*/
}
.dropdown button:focus{
    /*background-color: #424db0;*/
}

.same-height-container {
    display: flex;
    align-items: center;
}

.same_height {
    min-height: 43px;
}


.tabla{
    border-collapse: separate;
    border-spacing: 0 15px;
}
.tr_data{
    background-color: #f5f5ff;
    height: 60px;
    /*border: 3px solid #ff0000;*/
}
.tr_data:hover{
    /*cursor: pointer;*/
    /*background-color: #fff;*/
}
.tr_data>td{
    vertical-align: middle;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd !important;

}
.tr_data>td:first-child{
    border-left: 1px solid #ddd;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}
.tr_data>td:last-child{
    border-right: 1px solid #ddd;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}



.tr_data>td>span{
    vertical-align: middle;
}


@media only screen and (max-width: 1130px) {
    .contiene_tabla {
        width: 90vw;
        font-size: 12px;

    }
    .tr_data{
        padding: 0;
        margin: 0;
    }
    .mostrar_nuevo_reclamo{
        width: 70vw;
    }
}
@media only screen and (max-width: 950px) {
    .contiene_tabla {
        width: 100%;
    }
}
@media only screen and (max-width: 680px) {
    .contiene_tabla {
        width: calc(100vw - 20px);

    }
}
