.home{
    display: flex;
    flex-direction: column;
    /*margin-left: 1rem;*/
    flex-grow: 1;
    align-items: center;
    margin-top: 1.2rem;
    min-height: 100vh;
    padding: 0 6rem;
    /*height: calc(100vh - 6rem);*/
    /*overflow-y: scroll;*/

}

/*.claim_all::-webkit-scrollbar {*/
/*    width: 6px;*/
/*    height: 6px;*/
/*}*/

/*.claim_all::-webkit-scrollbar-thumb {*/
/*    background-color: transparent;*/
/*    border-radius: 3px;*/
/*}*/

/*.claim_all::-webkit-scrollbar-thumb:hover {*/
/*    background-color: #666;*/
/*}*/

/*.claim_all::-webkit-scrollbar-thumb:active {*/
/*    background-color: #333;*/
/*}*/

/*.claim_all::-webkit-scrollbar-thumb:window-inactive {*/
/*    background-color: #ccc;*/
/*}*/

/*.claim_all::-webkit-scrollbar-track {*/
/*    background-color: transparent;*/
/*}*/

/*.claim_all::-webkit-scrollbar-track-piece {*/
/*    background-color: transparent;*/
/*}*/

/*.claim_all::-webkit-scrollbar-thumb:vertical {*/
/*    height: 50px;*/
/*}*/

/*.claim_all::-webkit-scrollbar-thumb:horizontal {*/
/*    width: 50px;*/
/*}*/

/*.claim_all::-webkit-scrollbar-thumb {*/
/*    display: none;*/
/*}*/

/*QUITAR LOS OVERFLOW AUTO DE LAS TABLAS */


@media only screen and (max-width: 1620px) {
    .home{
        padding:3rem;
    }
}
@media only screen and (max-width: 1500px) {
    .home{
        padding:1rem;
    }
}
@media only screen and (max-width: 892px) {
    .home {
        margin-left: 0;
    }

}
