*{
    /*overflow-x: hidden;*/
    margin: 0;
}

body{
    min-height: 100vh;
}
.body_page{
    display: flex;

    /*place-content: center;*/
}
@media screen and (max-width: 990px)  {
    .sidebar{
        position: absolute;
        top:96px;
        bottom:0
    }
}
