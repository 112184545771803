.photo{
    background-color: #e7e9ff;
    border-radius: 10px;
    box-shadow: inset 2px 2px 10px 2px #fff, 1px 1px 5px #888;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;

}
.photo:hover{
    background-color: #e7e9ff;
    box-shadow: inset 2px 2px 5px  #aaa, 1px 1px 2px #888;

}
.moneda>input{
    background-color: #f1f3fe;
    box-shadow: inset 1px 1px 3px #ccc;
}
.moneda{
    max-width: 20ch;
    background-color: #f1f3fe!important;
    box-shadow: inset 1px 1px 3px #ccc;
}

.photocon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /*min-height: 250px;*/
    /*min-width: 250px;*/

    /*max-width: 350px;*/
    min-height: 200px;
}
/*.photocon>div{*/
/*    !*align-self: center;*!*/
/*}*/
.photocon>div>img{
    height: 100%;
    width: 100%;
    background-color: #bbb;
    border-radius: 10px;
    padding: 5px;
    object-fit: cover;
}

.selects_input_details{
    margin: .3rem 0 1.5rem 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}
.selects_input_details_disabled{
    margin: .3rem 0 1.5rem 0;
    font-size: 16px;
    background-color: #ececec !important;
    color: #ececec;
    box-shadow: inset 1px 1px 3px #ccc;
    pointer-events: none;
}
/*style={{display:"flex",color:'blue', width:"20ch", border:'2px blue solid',padding:'.4rem 1rem', marginRight:'2rem', borderRadius:7,alignItems:"center"}}*/
.buttons_companies{
    display: flex;
    color: #5a68e8;
    width: 20ch;
    font-weight: 600;
    font-size: 1.2rem;
    background-color: #eff1fc;
    border: 2px #5a68e8 solid;
    padding: .4rem 1rem;
    margin: 0 1.5rem 1rem 0;
    border-radius: 7px;
    align-items: center;
}
.boton_mas{
    font-size: 20px;
    margin: 0 1rem 0 0;
    justify-self: center;
    align-self: center;
    cursor: pointer;
    color: #4d59cb;
}
.boton_mas:hover{
    color: #2c2eb4;
    background-color: #e7e9ff;
    box-shadow: 1px 1px 3px #ccc;
}