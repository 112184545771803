@value fontBig, fontMedium, fontSmall from '../../../styles/colors.css';
.tarjeta{
    justify-content: center;
    width: 90vw;
    display: flex;
    padding: 0.5rem;
    margin: 2rem auto 0 auto;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 3px #bbb ;
}
.titulo{
    font-size: fontMedium;

}
/*.tarjeta *{*/
/*    font-size: calc(13px + (17 - 13) * ((100vw - 300px) / (1600 - 300)));*/
/*}*/
.photo{
    font-weight: bold;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    min-width: 20vw;
    text-align: center;
    margin: auto;
}
.photo p{
    font-size: fontMedium;
}
.photo img{
    margin-bottom: 1rem;
    height: 18vw;
    width: auto;
    /*max-width:fit-content;*/
    align-self: center;
}
.detail{
    min-width: 50vw;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0.5rem;
    border-left: solid #ccc 2px;
}
.avoidwrap{
    display: inline-block;
    vertical-align: bottom
}
.detail>p{
    font-weight: bold;
}
.detail_up{
    display: flex;
    padding-bottom: 1rem;
    gap: 1vw;
    flex-grow: 3;
    border-bottom: solid #ccc 2px;
}
.detail_up_title{
    font-weight: 400;
    color: #999;
    margin-bottom: 0;
}

.detail_down{
    padding: 1rem .5rem 0 .5rem;
}
.detail_down a{
    text-decoration: none;
}
@media only screen and (max-width: 510px) {

    .detail_up {
        display: block;
        }

}
@media only screen and (min-width: 870px) {
    .tarjeta {
        width: 67vw;
        padding: 1rem;
    }
    .detail{
        margin: 0 0.5rem;
        padding: 1rem;
    }

    .photo {

        margin-bottom: 1rem;
        padding-left: 2rem;
        align-self: center;
    }
    .photo img{
        width: 70%;
        height: auto;
        align-self: center;

    }
}
@media only screen and (min-width: 1080px) {
    .tarjeta {
        width: 70vw;
    }

}

@media only screen and (min-width: 1280px) {
    .photo img{
        width: 50%;
    }
}