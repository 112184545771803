@value custom: "../../../styles/colors.css";
@value fontBig, fontMedium, fontSmall from custom;

.filtro_total{
    margin: 2rem 0;
    width: 100%;
}
.title_claimed{
    font-size: 1.5rem;
    font-weight: 600;
    align-self: start;
    /*margin-left: 7vw;*/
    margin-bottom: 0;
}
.title_filter{
    font-weight: bold;
    margin: 0 0.5rem 0.5rem 0.5rem;
}

.filtros_busqueda *{
}
.filtros_busqueda{
    justify-content: center;
    /*width: calc(100vw - 350px);*/
    flex-direction: column;
    display: flex;

    padding: 0.5rem;
    margin: 2rem auto 0 auto;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 3px #bbb ;
}
.selects_input{
    margin: 0.3rem 0 1.5rem 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}
.opciones_busqueda div{
    display: inline-block;
    /*vertical-align: text-bottom;*/
    margin: 0 1vw 4px;
}
.opciones_busqueda div * {
    margin-bottom: 1rem;
}
.opciones_busqueda button {
    margin-bottom: -.5rem;
}
.opciones_busqueda>form>div>div>input{
    background-color: #f1f3fe;
    box-shadow: inset 1px 1px 3px #ccc;
}
.opciones_busqueda>form>div>select{
    background-color: #f1f3fe;
    box-shadow: inset 1px 1px 3px #ccc;
}

.opciones_date{
    margin: 0 0 0 0 !important;
    display: flex;
    flex-direction: row;
}
.opciones_date_in{
    margin: 0 1vw 3px !important;
}

.boton_filtro{
    vertical-align: baseline;
    margin-bottom: 6px !important;
    color: whitesmoke;
    /*background-color:primary ;*/
    /*border-color: primary;*/
}
/*.boton_filtro:hover{*/
/*    background-color: primary;*/
/*}*/
/*.boton_filtro:focus{*/
/*    background-color: primary;*/
/*}*/


@media only screen and (max-width: 1508px) {
    .opciones_busqueda div{
        margin: 0 10px 4px;
    }
}
@media only screen and (max-width: 1376px) {
    .opciones_busqueda div{
        margin: 0 5px 4px;
    }
}
/*}*/
/*@media only screen and (max-width: 1700px) {*/
/*    .title_claimed{*/
/*        margin-left: 0;*/
/*    }*/
/*}*/

/*@media only screen and (max-width:720px) {*/
/*    .filtros_busqueda {*/
/*        !*width: 87vw;*!*/
/*        padding: 1rem;*/
/*    }*/
/*}*/
