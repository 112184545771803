.columna p{
    margin: 0;
}
.columna{
    padding:1rem
}
.titulo{
    color: #999999;
}

.modal_reclamo{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /*padding: 0 10%;*/
    align-items: center;
    /*border-bottom: 1px solid #ddd;*/
    /*border-radius: 10px;*/
}
.selects_input{
    width: 100%;
    display: flex;
    /*justify-content: center;*/
    padding-left: 2rem;
    margin-bottom: 2rem;
}


.tabla{
    border-collapse: separate;
    border-spacing: 0 15px;
}
.tr_data{
    background-color: #f9f9f9;
    height: 80px;
    border: 2px solid #ddd;
}
.tr_data:hover{
    cursor: pointer;
    background-color: #e6e7ef;
}
.tr_data>td{
    vertical-align: middle;
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
    padding-left: 2rem;

}
.tr_data>td:first-child{
    border-left: 2px solid #ddd;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}
.tr_data>td:last-child{
    border-right: 2px solid #ddd;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.tr_data_select{
    background-color: #d6d3ee;
    height: 80px;
    border: 2px solid #777;
}
.tr_data_select:hover{
    cursor: pointer;
    background-color: #9ba1e0;

}
.tr_data_select>td{
    vertical-align: middle;
    border-top: 2px solid #999;
    padding-left:2rem ;
    }
.tr_data_select>td:first-child{
    border-left: 2px solid #aaa;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}
.tr_data_select>td:last-child{
    border-right: 2px solid #777;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}