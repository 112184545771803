/*@value custom from '../../../styles/colors.css';*/
@value fontBig, fontMedium, fontSmall from '../../../styles/colors.css';
@value primary, primary2 from '../../../styles/colors.css';
.surveyor_details_all {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: 2rem;
    padding: 1rem 1.2rem 1.5rem ;
    box-shadow: 0 0 3px #bbb ;
}
.surveyor_details_big_title {
    align-self: start;
    font-size: fontBig;
    font-weight: 500;
    margin: .5rem 0 0 .5rem;
}
.surveyor_details_title{
    font-size: fontMedium;
    font-weight: 500;
}
.personal_details_container{
    display: flex;
    /*justify-content: space-between;*/
}
.personal_details_container>div{
    margin: 1rem 3vw 0;
}
.foto_perf_cont{
    height: 50px;
    display: flex;
    align-items: center;
    margin-left: 0 !important;
}
.rating{
    display: inline-flex;
    align-items: center;
    margin-left: .3rem;
    font-size: 12px;
    color: #666;
}
.foto_perfil{
     height: 100%;
     width: 50px;
     object-fit: cover;
     border-radius: 50%;
     margin-right:1rem;
 }
.personal_details_container2{
    display: flex;
    justify-content: flex-start;
}
.personal_details_container2>div{
    margin: 1rem 4vw 0 0;
}
.input_style{
    margin: .3rem 0;
    font-size: fontSmall;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}

.form_group{
    display: flex;
    flex-wrap: wrap;

}
.form_group>div{
    margin: 0 1rem 0 0;
}
.form_group>button{
    align-self: baseline;
}
.selects_input{
    margin-top: .7rem;
}
.selects_input input{
    margin: .3rem 0 1.5rem 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}
.boton_filtro{
    margin: 0 0 0 1rem;
    justify-self: center;
    align-self: center;
    height: 70%;
}
.contiene_tabla{
    margin: 3rem 0 ;
    /*border-top: 1px solid #444;*/
    border-collapse: separate;
    justify-content: center;
    width: 100%;
    border-top: 1px solid #ccc;
    /*border-radius: 6px;*/
    overflow: auto;
}

.file_input{
    margin: .7rem 0 1.5rem 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}
.file_input::file-selector-button {
    /*border-color: transparent;*/
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    color: transparent !important;
    width: 10%;
    height: 100%;
    background: url("../../../styles/icon2.svg") no-repeat center center;
}
.file_input_look{
    margin: .7rem 0 1.5rem 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}
.button{
    margin: .7rem 0 1.5rem 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
}
.button:hover{
    background-color: #7278ab !important;
}

