.barra_numeros{
    margin-top: 1rem;
    padding: 0 5%;
    display: flex;
    align-self: center;
    justify-content: space-between;
}
.numeros{
    display: flex;
    column-gap: 1rem;
    justify-content: flex-start;
    min-width: 35vw;
}
.negrita_numeros{
    font-size: 19px;
    font-weight: bold;
}
.form_selection{
    width:27ch !important;
    margin-left:1vw;
    margin-right:2vw
}
.status{
    align-self: center;
}
.selects_input{
    margin: .3rem 0 0.5rem 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}
.input_buttonadd {

    background-color: #eff1fd;
    border-color: #7884ff;
    color: #7884ff;
    font-weight: bolder;
    border-style: dashed;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    margin: 0 auto;
    padding: 0;
    display:block;
    text-align: center;
}
.status2{
    /*border: #bbb 2px solid;*/
    border-radius: 5px;
    padding: .5rem 1rem;
    background-color: #fdfdfd;
    color: #3b3b3b;
    box-shadow:  0px 0px 3px #111;
}
.status2 p{
    font-weight: 600;
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
}
.input_buttonadd:hover{
    background-color: #c8ccff;
    color: #5660ce;
    border-color: #5660ce;
    cursor: pointer;
  }

.input_buttonadd[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
  }
.input_buttonremove {
    /*background-color: #eeeeee !important;*/
    /* border-color: rgb(241, 65, 65); */
    /* border-style: dotted; */
    /* border-width: 1px; */
    background-color: transparent !important;
    border-color: transparent;
    /* border-left: 5px solid transparent; */
    text-align: center;
    color: red ;
    width: 30px;
    height: 30px;
    /* background: url("../../styles/icon3.svg") no-repeat center center; */
}
.refnumbers {
    display:flex;
    flex-direction: row;
    align-items: center;
    border-width: 10px;
}
.inputrefnumbers{
    margin: 0 0 0.2rem 0.2rem;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}
@media only screen and (max-width: 700px) {
    .barra_numeros{
        flex-direction: column;
        justify-content: space-between;
        row-gap: 1rem;
        align-items: center;
    }

    .numeros{
        display: flex;
        column-gap: 1rem;
        justify-content: center;
        align-items: center;
        min-width: 40vw;
    }
}