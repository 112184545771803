.topnav{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*background-color: whitesmoke;*/
    height: 6rem;
    border-bottom: 2px #ddd solid;
    padding: 0 0 0 2vw !important;
    position: fixed;
}
.titulo{
    /*calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));*/
    font-size: calc(18px + (26 - 18) * ((100vw - 300px) / (1600 - 300)));
    margin-left: 5vw;
    margin-block: auto;
    font-weight: 600;
    color: black;

}
.hamburger{
    margin-left: 15px;
}
.hamburger :hover{
    background-color: #dadada;
    border: 1px;
    border-radius: 3px;
}

.rsk_icon{
    visibility: visible;
}
.rsk_icon img{
    width: 150px;
    height: auto;
}

.icon_title{
    display: flex;
    flex-direction: row;
}
.form_selection{
    width:30ch !important;
    /*marginLeft:1vw;*/
    /*marginRight:2vw*/
}
.lang{
    position: relative;
    top:10px;
    right: 10px;
}

.lang>select{
    border: #b5bbf3 solid 3px;
    border-radius: 10px;
}

.bell{
    width:40px;
    height:40px;
    border:#aaaaff 3px solid;
    box-shadow:1px 1px 2px black;
    border-radius:100%;
    display:flex;
    justify-content:center;
    align-items:center;
}
.bell:hover{
    box-shadow:0 0 1px black;
    cursor:pointer;
    background: #f3f3ff;
}
.bell:active{
    background: #e3e3ff;
}
.bell_pulse{
    width:40px;
    height:40px;
    border: #b55 3px solid;
    box-shadow:1px 1px 2px black;
    border-radius:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color: red;
    animation-name: color_pulse;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
}
.bell_pulse:hover{
    box-shadow:0 0 1px black;
    cursor:pointer;
    background: #f3f3ff;
}
.bell_pulse:active{
    background: #e3e3ff;
}
@keyframes color_pulse {
    0% {
        background-color: #f67070;
    }
    35% {
        background-color: white;
    }
    65% {
        background-color: white;
    }
    100% {
    background-color: #f67070;
    }
}
.notihover{
    background: white;
    border-bottom:2px solid #ccc;
    padding:1rem
}
.notihoverlast{
    background: white;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: 1rem;
}
.notihover:hover{
    background: #ededfd;
    box-shadow:0 0 2px 1px #bbb, inset 1px 1px 3px 1px white;
    border-bottom: 2px solid #b7b7b7;
    cursor: pointer;
}
.notihover:active{
    background: #f7f7ff;
    box-shadow:0 0 2px 1px white, inset 1px 1px 3px 1px #999;

}
.notihoverlast:hover{
    background: #ededfd;
    box-shadow:0 0 2px 1px #bbb, inset 1px 1px 3px 1px white;
    border-bottom: 1px solid #b7b7b7;
    cursor: pointer;
}
.notihoverlast:active{
    background: #f7f7ff;
    box-shadow:0 0 2px 1px white, inset 1px 1px 3px 1px #999;

}
.notif{
    background: #d8d8ff;
    border: #aaaaff 3px solid;
    border-radius: 10px;
    padding: 5px;
    margin: 10px 5px 10px 20px;
    box-shadow: 1px 1px 3px #888;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.notif:hover{
    background: #f3f3ff;
    cursor: pointer;
    box-shadow: 0 0 1px black;
}
@media only screen and (max-width: 870px) {
    .rsk_icon img {
        height: 50px;
        width: auto;
    }

    .form_selection{
        font-size: 10px !important;
        width: 120px !important;
        margin-left:0;
        margin-right:0
    }
    .icon_title{
        display: flex;
        flex-direction: row;
    }
    .titulo{
        margin-left: 0vw;
        font-size:12px
    }

    .icon_title{
        display: flex;
        flex-direction: column;
    }
}

