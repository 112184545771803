@value fontBig, fontMedium, fontSmall from '../../../styles/colors.css';
.transporte_titulo{
    display: inline-flex;
    margin: 1.5rem;
}

.texto_inOut{
    margin-left: .5rem;
    align-self: center;
}

.inicio_destino{
    font-weight:bold;
    margin: 0 1rem;
    align-self: center;
}
.form_selection{
    height: 2.3rem;
}

.moneda>input{
    background-color: #f1f3fe;
    box-shadow: inset 1px 1px 3px #ccc;
}
.moneda{
    max-width: 20ch;
    background-color: #f1f3fe!important;
    box-shadow: inset 1px 1px 3px #ccc;
}
.errors{
    color: purple;
    font-size: 13px;
    margin: 0;
    padding: 0;
}
.selects{
    display: flex;
    padding: .5rem 0 .5rem .5rem;
    /*background: #ebefeb;*/
    align-items:center;
    justify-content: space-between;
}
.selects input{
    min-width: 22ch;
    margin:.6rem 1rem;

    height: 2.5rem;
    font-size: 16px;
    background: #f1f3fe;
    box-shadow: inset 1px 1px 3px #ccc;
}
.selects select{
    width: 25ch;
    height: 2.5rem;
    font-size: 16px;
    background: #f1f3fe;
    box-shadow: inset 1px 1px 3px #ccc;
}
.selectsBL{
    display: flex;
    padding: .5rem 0 .5rem .5rem;
    /*background: #ebefeb;*/
    align-items:center;
    justify-content: space-between;
}
.selectsBL input{
    /*width: 12ch;*/
    margin: .8rem 0;
    height: 2.5rem;
    font-size: 16px;
    background: #f1f3fe;
    box-shadow: inset 1px 1px 3px #ccc;
}
.typeahead{
}

.typeahead div{
    min-width: 240px;
}
.typeahead div input{
    margin: 10px 0;

    /*margin: 1rem 0;*/
}

.typeahead2{

    display: inline;
    font-size: fontSmall;
    width: 8ch;
    /*min-width: 200px;*/
    /*flex-direction: column;*/
}
.rowtypehead {
    display:flex;
    flex-direction: row;
    align-items: center;
    border-width: 10px;
}
.typeahead2 input{
    display: inline !important;
    box-shadow: inset 1px 1px 3px #ccc;
    background: #f1f3fe;
}
.selects_input{
    background-color: #f1f3fe !important;
}
.selects_input_disabled{
    color: #525252 !important;
    background-color: #f5f5f5 !important;
}
.typeahead3 input{
    display: inline !important;
    box-shadow: inset 1px 1px 3px #ccc;
    background: #f1f3fe;
    margin-right: 0rem;
    padding:.8rem;
    width: 20ch;
}
.polPod{
    width: min-content;
    margin-left:1rem;
    display:inline-flex;
    box-sizing: border-box;
    min-width: 40ch;
    justify-content: center;
}
.selects_m{
    display: flex;
    padding: .5rem;
    /*background: #ebefeb;*/
    align-items:center;
    justify-content: space-between;
}
.selects p{
    max-width: 50%;
    min-width: 13ch;
    text-align: right;
}
.monto_recuperado{
    margin: 1.5rem 0 1rem 1.5rem;
    padding: 1rem;
    border: 2px solid #ccc;
    border-radius: 6px;
    min-width: 25ch;
}
p{
    margin: .3rem 0;
}
.labels{
    max-width: 50%;
    min-width: 13ch;
    text-align: right;
    margin: .3rem 0;

}

/* hace un media query para que se vea bien en pantallas pequeñas */
@media screen and (max-width: 1350px) {

    .selects input {
        min-width: 23ch;
        font-size: 16px;
    }

    .typeahead div input {
        min-width: 23ch;
        font-size: 16px;
    }
}
@media screen and (max-width: 1114px) {

    .selects input{
        min-width: 22ch;
        font-size: 12px;
    }
    .polPod{
        padding: 1rem 0;
        align-items: center;
        justify-self: center;
    }
}
@media screen and (max-width: 630px) {

    .container{
        display: flex;
        flex-direction: column;
    }
    .typeahead div{
        min-width: 140px;
    }
}


