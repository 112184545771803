.all_div{
    margin: 2rem auto 0 auto;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 0.5rem;
    justify-content: center;
    box-shadow: 0 0 3px #bbb ;
}
.collapse_icon{
    border: 1px #ddd solid;
    border-radius: 15px;
    padding: .4rem .8rem;
    cursor: pointer;
    float: right;
    margin: .4rem 1rem 0 1rem;
}
.collapse_icon svg{
    margin-top: -4px;
}

.collapse_icon:hover{
    background-color: #ddd;
}
.tabs*:hover{
    /*padding: 2rem;*/
}
.tabs>ul{
    border-bottom: #edeefc 2px solid !important;
}
.tabs ul{
    padding: .6rem 2rem 0 2rem;
}

.tabs>ul>li{
    background-color: #ddd;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 0 1px;
    box-shadow: 2px -1px 2px #777;
}
.tabs>ul>li>button{
    cursor: initial !important;
}
.div_radio{
    display: block;
    margin: .8rem 0 1rem ;
}
.radio_buttons{
    display: flex;
    margin: 0.5rem 1rem;
    gap: .3rem;
    align-items: center;
    padding: .4rem 0;
}
.radio_buttons>div>input{
    cursor: pointer;
}

.radio_buttons_title{
    margin-right: 2rem;
    font-weight: bold;
    visibility: hidden;
    position: absolute;
    align-self: start;
}
.form-check{
    margin: 0;
}

@media only screen and (min-width: 870px) {
    .radio_buttons{
        padding:0;
    }
    .radio_buttons_title{
        visibility: visible;
        position: relative;
    }
}
@media only screen and (min-width: 1080px) {
    /*.all_div {*/
    /*    width: 100%;*/
    /*    flex: auto;*/
    /*}*/

}