@value colors: "../../../../styles/colors.css";
@value primary, primary2 from colors;
.selects{
    display: flex;
    flex-direction: column;
    padding: .5rem 0;
    align-items:center;
    /*border: 1px red solid;*/
    /*justify-content: flex-end;*/
}


.selects input{
    min-width: 10ch;
}
.errors{
    color: purple;
    font-size: 13px;
    margin: 0;
    padding: 0;
}
.selects_m{
    display: flex;
    padding: .5rem;
    /*background: #ebefeb;*/
    align-items:center;
    justify-content: space-between;
}
.selects_p{
    text-align: center;
}
.selects>div>input{
    /*max-width: 50%;*/
    min-width: 15ch;
    text-align: left;
}
.selects_mini{
    display: flex;
    padding: .5rem 0;
    flex-direction: column;
    /*border: 1px #8c00ff solid;*/
    align-items:center;
    /*justify-content: flex-end;*/
}
.selects_mini>div>input{
    /*max-width: 50%;*/
    width: 7ch;
    text-align: left;
}
.selects_mini>div{
    align-self: start;
}
.tipo_danio{
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
}
.tipo_danio>div{
    /*flex-direction: row;*/
    justify-content: flex-start;
    align-items: self-start;
}
.nota{
    background-color: primary2;
    padding: 1rem;
    border: 1px solid primary;
    border-radius: 10px;
    margin: 15rem 0rem 1rem 0rem;
}
.monto_recuperado{
    margin-top: 8px;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 6px;
}
.selects_input input{
    background-color: #f4f5ff !important;
    box-shadow: inset 1px 1px 2px #ccc;
}
.selectsin{
    background-color: #f4f5ff !important;
    box-shadow: inset 1px 1px 2px #ccc;
}
.selectdis{
    color: red !important;
    background-color: purple !important;
}

/*.selects_input input:hover{*/
/*    background-color: #e0e2f1 !important;*/
/*    box-shadow: inset 1px 1px 2px #ccc;*/
/*}*/
.selects_input_disabled input{
    color: #525252 !important;
    background-color: #f5f5f5 !important;


}
p{
    /*align-self: flex-start;*/
    margin: .3rem 0;
}
.title_carga{
    margin: 2rem 0 0 2rem;
    font-size: 25px;
}
.title_carga2{
    margin: 0 0 0 2rem;
    font-size: 25px;
}
.titles{
    margin-top: 2rem;
    font-size: 25px;
}
.columns{
    display: flex;
    margin-bottom: 3rem;
}
.columns_danio{
    display: flex;
    gap: 200px;

}
.container{
    margin:0 2rem;
    border-bottom: rgba(170, 170, 170, 0.2) solid 2px;
}
.add_especie{
    display:inline-flex;
    margin:.5rem 0 0 2rem;
    padding:.5rem;
    border:1px solid #9399ea;
    border-radius:7px;
    background:primary2


}
/*.add_especie:hover{*/
/*    background-color: #e3e3e3;*/
/*    box-shadow: 1px 0px 1px #bbb;*/
/*}*/
.add_fecha{
    margin: .5rem 0;
    display: flex;
    font-size: 14px;
}
.add_fecha_plus{
    border: 1px rgba(99, 106, 141, 0.62) solid;
    border-radius: 50%;
    /*display: inline;*/
    /*margin: 1rem 2rem 2.8rem 2rem;*/
    padding: .3rem;
    cursor: pointer;
    width: 1.7rem;
    height: 1.7rem;
    margin-left: .2rem;
    background-color: #97a4d8;
    box-shadow: inset 2px 2px 5px #eee,1px 1px 1px #aaa;

    display: flex;
    align-items: center;
    justify-content: center;
}
.add_fecha_no{
    border: 1px rgba(99, 106, 141, 0.62) solid;
    border-radius: 50%;
    /*display: inline;*/
    /*margin: 1rem 2rem 2.8rem 2rem;*/
    padding: .3rem;
    cursor: pointer;
    width: 1.7rem;
    height: 1.7rem;
    margin-left: .2rem;
    background-color: #ddd;
    box-shadow: inset 2px 0px 1px #aaa;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add_fecha_plus:hover{
    background-color: primary2;
    box-shadow: 1px 0px 1px #bbb;
}
@media only screen and (max-width: 1205px) {
    .columns{
        display: inline;
        padding:0;
    }
    .selects_mini>div{
        align-self: center;
    }
    /*p{*/
    /*    align-self: center;*/
    /*}*/
    .selects_p{
        align-self: self-start;
    }

}
@media only screen and (max-width: 605px) {
    .row>div:first-child{
        min-width: 100%;
    }
}
@media only screen and (min-width: 605px) {
    .selects{
        max-width: 30vw;
    }
}
