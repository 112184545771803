@value custom: "../../../../styles/colors.css";

@value fontBig, fontMedium, fontSmall from custom;

.container{
    margin: 1.5rem 0 0 0;
    border:1px solid #ddd;
    border-radius: 10px;
}
.container2{
    margin: 0rem;
    /*border:2px solid #ddd;*/
    /*border-radius: 10px;*/
}
.container3{
    margin: 1.5rem 0 0 0;
    border:1px solid #ddd;
    border-radius: 10px;
}
.download_icon{
    color:#555;
    font-size:20px;
    vertical-align:text-bottom;
}

.container2>div>div{
margin: 1rem 5rem;
}
.container2>div>div>div>div>div>button{
    background-color: #eee;
}
.container2>div>div>div>div>div>button:hover{
    background-color: #b5b9c5;
}

.title_main{
    font-size: 22px;
    padding: 1rem 2rem 0 2rem;
}
.title{
    font-size: fontSmall;
    padding:.5rem 0;
}
.button_des{
    display: inline-flex;
    padding: 8px;
    align-self: center;
    vertical-align: center;
    border-radius: 6px;
    background-color: #e0e2f1;
    margin-left: 1rem;
    border: 1px solid #aaa;
    box-shadow: 1px 1px 2px #c7c7c7dd, inset 2px 2px 3px #fff;
    cursor: pointer;
}
.button_des:hover{
    background-color: #ccd0dc;
    box-shadow: inset 1px 1px 2px #666;

}
.container>div>div{
    margin: 1rem 4rem;
}

.svg_icon{
    background-size:100%;
    display:inline-block;
    width:1rem;
    height:1rem;
    background: url("../../../../styles/calendar.svg") no-repeat center;
}

.addicon {

    color: blue;
    width: 16%;
    margin: 0.3rem;
    background: url("../../../../styles/icon3.svg") no-repeat center center;
}
.removeicon {
    color: red;
    width: 16%;
    margin: 0.3rem;
    background: url("../../../../styles/icon4.svg") no-repeat center center;
}

.add_inputfile {
    border-color: transparent;
    border-left: 5px transparent;
    color: blue;
    min-width: 10ch;
    margin: 0.3rem;
    width: 40%;
}

.input_buttonremove {
    background-color: transparent !important;
    padding: .5rem 0;
    margin: 0 .5rem;
    font-weight: bolder;
    border: solid 2px #f44;
    border-radius: 50%;
    text-align: center;
    line-height: 0;
    color: red ;
    width: 22px;
    height: 22px;
}
.plantilla_des{
    margin: .3rem 0 0 0;
}
.plantilla_des2{
    margin: .5rem 0 0 0;
    box-shadow: 1px 1px 3px #ccc;
    padding: 5px;

}

.file_input{
    margin: .3rem 0 0 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}
.file_input_disabled{
    margin: .3rem 0 0 0;
    font-size: 16px;
    background-color: #eaeaea !important;
    box-shadow: inset 1px 1px 3px #ccc;
}
.file_input2{
    margin: .3rem 0 0 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
    padding: 0;
}
.file_input[type=file]{
    margin: .3rem 0 0 0;
    height: 38px;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}
.file_input2[type=file]{
    /*color: transparent;*/
    margin: .5rem 0;
    padding: .3rem;
    width: 300px;
    height: 35px;
    line-height: 1rem;
    font-size: 0;
    box-shadow: 1px 1px 3px #aaa;
}
.file_input_disabled[type=file]{
    /*color: transparent;*/
    margin: .5rem 0;
    padding: .3rem;
    width: 300px;
    height: 35px;
    line-height: 1rem;
    font-size: 0;
    box-shadow: 1px 1px 3px #aaa;
}
.file_input_disabled[type=file]:after{
    font-size: 16px;
    vertical-align: middle;
    margin: 0;
    content: 'Can not be edited';
    color: #000;
    line-height: 24px;
    display: inline-block;
    /*padding-left: 10px;*/
    padding-bottom: 0;
}

.file_input2[type=file]:after{
    font-size: 16px;
    vertical-align: middle;
    margin: 0;
    content: 'Sobreescribir archivo';
    color: #000;
    line-height: 20px;
    display: inline-block;
    /*padding-left: 10px;*/
    padding-bottom: 0;
}
.file_input2[type=file]:hover{
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;

}


.button_input:hover{
    box-shadow: inset 1px 1px 3px #414186;
    background-color: #6e6ed3 !important;
}

.file_title{
    margin-top: 1.5rem;
}

.form_control{
    background-color: #4d59cb;
}
.file_input::file-selector-button  {
    /*background-color: #eeeeee !important;*/
    margin-bottom: 0 !important;
    padding: 0 15px 0 0 !important;

    border-color: transparent;
    border-left: 12px solid transparent;
    border-right: 10px solid transparent;
    color: transparent !important;
    width: 50px;
    height: 150%;
    background: url("../../../../styles/icon.svg") no-repeat center center;
}
.file_input_disabled::file-selector-button  {
    background-color: transparent !important;
    border-right: transparent !important;

}
.file_input2::file-selector-button {
    /*background-color: red !important;*/

    margin-bottom: 0 !important;
    padding: 0 15px 0 0 !important;

    border-color: transparent;
    border-left: 12px solid transparent;
    border-right: 5px solid transparent;
    color: transparent !important;
    width: 55px;
    height: 150%;
    background: url("../../../../styles/icon.svg") no-repeat center center;

}

.selects_input{
    background-color: #f1f3fe !important;
}
.selects_input_disabled{

    color: #525252 !important;
    background-color: #f5f5f5 !important;
}
.gradient_historico2{
    /* scroll bar width, for use in mask calculations */
    --scrollbar-width: 18px;

    /* mask fade distance, for use in mask calculations */
    --mask-height: 72px;

    /* If content exceeds height of container, overflow! */
    overflow-y: auto;

    /* Our height limit */
    height: 60vh;

    /* Need to make sure container has bottom space,
  otherwise content at the bottom is always faded out */
    padding-bottom: var(--mask-height);

    /* Keep some space between content and scrollbar */
    padding-right: 20px;

    /* The CSS mask */

    /* The content mask is a linear gradient from top to bottom */
    --mask-image-content: linear-gradient(
            to bottom,
            black,
            transparent 0px,
            black var(--mask-height),
            black calc(100% - var(--mask-height)),
            transparent
    );

    /* Here we scale the content gradient to the width of the container
  minus the scrollbar width. The height is the full container height */
    --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;

    /* The scrollbar mask is a black pixel */
    --mask-image-scrollbar: linear-gradient(black, black);

    /* The width of our black pixel is the width of the scrollbar.
  The height is the full container height */
    --mask-size-scrollbar: var(--scrollbar-width) 100%;

    /* Apply the mask image and mask size variables */
    mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
    mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

    /* Position the content gradient in the top left, and the
  scroll gradient in the top right */
    mask-position: 0 0, 100% 0;

    /* We don't repeat our mask images */
    mask-repeat: no-repeat, no-repeat;

}
.inicia{
    padding-left: 2rem;
    overflow-y: hidden
}
.selects_input_details{
    margin:  0 1rem 2rem 0;
    width: 80%;
    align-self: center;
}
.mensaje{
    margin-right: 2rem;
}
.mensaje div{
    background-color: #e5e5ff;
    font-size: 18px;
}
.mensaje div > svg{
    fill: #e5e5ff;
}
.mensajeRes{
    margin-left: 2rem;
}
.mensajeRes div {
    font-size: 18px;
    background-color: #fffcef;
}
.mensajeRes div > svg{
    fill: #fffcef;
}
.gradient_historico{
    /* scroll bar width, for use in mask calculations */
    --scrollbar-width: 18px;

    /* mask fade distance, for use in mask calculations */
    --mask-height: 12px;

    /* If content exceeds height of container, overflow! */
    overflow-y: auto;

    /* Our height limit */
    height: 60vh;

    /* Need to make sure container has bottom space,
  otherwise content at the bottom is always faded out */
    padding-bottom: var(--mask-height);

    /* Keep some space between content and scrollbar */
    padding-right: 20px;

    /* The CSS mask */

    /* The content mask is a linear gradient from top to bottom */
    --mask-image-content: linear-gradient(
            to bottom,
            black,
            transparent 0px,
            black var(--mask-height),
            black calc(100% - var(--mask-height)),
            transparent
    );

    /* Here we scale the content gradient to the width of the container
  minus the scrollbar width. The height is the full container height */
    --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;

    /* The scrollbar mask is a black pixel */
    --mask-image-scrollbar: linear-gradient(black, black);

    /* The width of our black pixel is the width of the scrollbar.
  The height is the full container height */
    --mask-size-scrollbar: var(--scrollbar-width) 100%;

    /* Apply the mask image and mask size variables */
    mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
    mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

    /* Position the content gradient in the top left, and the
  scroll gradient in the top right */
    mask-position: 0 0, 100% 0;

    /* We don't repeat our mask images */
    mask-repeat: no-repeat, no-repeat;

}