.containerIcons{
    margin: 0.5rem 0.5rem 5px;
    /* height: 100%; */
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 3px #bbb ;
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    flex-wrap: wrap;
}
.containerIcon{
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-content: space-around;
    align-items:center;
    flex-grow: 1;
}
.containerInfo{
    /* height: 100%; */

    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 3px #bbb ;
    width: 50%;
    margin: .5rem;
    /*margin: 0.5rem 0.5rem 5px 29.5rem;*/
}
.containerRow{
    display: flex;
    flex-direction: row;
}
.itemRow{
    margin: 0 1rem 1rem;
    width: 50%;
}
.containerIcons>div>div{
margin: 1rem;
}
.containerInfo>div>div{
    margin: 1rem;
    }
/* .container2>div>div>div>div>div>button{
    background-color: #eee;
}
.container2>div>div>div>div>div>button:hover{
    background-color: #b5b9c5;
} */


.title{
    font-size: 13px;
    font-weight: bold;
}
.text{
    font-size: 15px;
}
.selects_input{
    font-size: 15px;
    background-color: #f1f3fe !important;
}
.selects_input_disabled{
    font-size: 15px;
    color: #525252 !important;
    background-color: #f5f5f5 !important;
}

@value colors: "../../../../styles/colors.css";
@value primary, primary2 from colors;
.selects{
    display: flex;
    flex-direction: row;
    align-items:center;
}
.selects_file{
    margin: 0;
}

.file_input{
    margin: .3rem 0 1.5rem 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}

.file_input::file-selector-button {
    /*background-color: #eeeeee !important;*/
    border-color: transparent;
    border-left: 5px solid transparent;
    color: transparent !important;
    width: 10%;
    height: 100%;
    background: url("../../../../styles/icon.svg") no-repeat center center;
}
.addicon {
    /*background-color: #eeeeee !important;*/
    /* border-color: transparent; */
    /* border-left: 5px solid transparent; */
    color: blue;
    width: 16%;
    /* height: 100%; */
    margin-right: 0.3rem;
    background: url("../../../../styles/icon3.svg") no-repeat center center;
}
.add_inputfile {
    border-color: transparent;
    border-left: 5px transparent;
    color: blue;
    min-width: 10ch;
    margin: 0.3rem;
    width: 40%;
}
.input_buttonremove {
    background-color: transparent !important;
    border-color: transparent;
    text-align: center;
    color: red ;
    width: 30px;
    height: 30px;
}
.selects_input{
    min-width: 10ch;
    margin: .3rem 0 1.5rem 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}


.titles_p{
    margin-top: 1rem;
    margin-left: 1rem;
}

.inputIntercambios {
    display: flex;
    flex-direction: row;
    margin: 1rem;
}

@media only screen and (max-width: 1205px) {
    .columns{
        display: inline;
        padding:0;
    }
    .selects_mini>div{
        align-self: center;
    }
    /*p{*/
    /*    align-self: center;*/
    /*}*/
    .selects_p{
        align-self: self-start;
    }
    .containerInfo{
        width: 100%;
    }

}
@media only screen and (max-width: 605px) {
    .row>div:first-child{
        min-width: 100%;
    }

}
@media only screen and (min-width: 605px) {
    .selects{
        max-width: 30vw;
    }

}

@media only screen and (max-width: 605px) {
    .inputIntercambios{
        max-width: 30vw;
        flex-direction: column;
    }
}


