.transporte > div:last-child{
    height: auto;
    transition: height .4s;
    transition-timing-function: ease-out;
    overflow: hidden;
}

.transporte_coll {
    overflow: hidden;
}
.transporte_coll > div:last-child{
    height: 70px;
    transition: height .4s;
    transition-timing-function: ease-in;
}

/*noinspection CssInvalidPropertyValue*/
.claim_all{
    flex-grow:1;
    min-height: 80vh;
    padding:0 5vw 3rem 5vw;
    /*height: calc(100vh - 6rem);*/
    overflow-y: scroll;
    width: -moz-available;          /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    width: stretch;
}
/*.claim_all::-webkit-scrollbar {*/
/*    width: 6px;*/
/*    height: 6px;*/
/*}*/

/*.claim_all::-webkit-scrollbar-thumb {*/
/*    background-color: transparent;*/
/*    border-radius: 3px;*/
/*}*/

/*.claim_all::-webkit-scrollbar-thumb:hover {*/
/*    background-color: #666;*/
/*}*/

/*.claim_all::-webkit-scrollbar-thumb:active {*/
/*    background-color: #333;*/
/*}*/

/*.claim_all::-webkit-scrollbar-thumb:window-inactive {*/
/*    background-color: #ccc;*/
/*}*/

/*.claim_all::-webkit-scrollbar-track {*/
/*    background-color: transparent;*/
/*}*/

/*.claim_all::-webkit-scrollbar-track-piece {*/
/*    background-color: transparent;*/
/*}*/

/*.claim_all::-webkit-scrollbar-thumb:vertical {*/
/*    height: 50px;*/
/*}*/

/*.claim_all::-webkit-scrollbar-thumb:horizontal {*/
/*    width: 50px;*/
/*}*/

/*.claim_all::-webkit-scrollbar-thumb {*/
/*    display: none;*/
/*}*/

@media only screen and (max-width: 1370px) {
    .claim_all{
        padding: 0 2vw;
    }
}
@media only screen and (max-width: 530px) {
    .claim_all{
        margin: 0;
    }
}