.typeahead{
    padding-top: 0;
    margin: 0 !important;
    display: inline;

    /*width: 20ch;*/
    /*min-width: 200px;*/
    /*flex-direction: column;*/
}
.typeahead input{
    margin-top: 5px;
    display: inline !important;
    box-shadow: inset 1px 1px 3px #ccc;
    background: #f1f3fe;
}