.all{
    display: flex;
    justify-content: center;
    overflow-x: hidden;
    flex-wrap: wrap;

}
.contenedor{
    position: relative;
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    height: 135px;
}
.circulo{
    height: 20px;
    width: 20px;
    border:1px solid #9fa8e0;
    background-color: #f9f9f9;
    border-radius: 50%;
    position: absolute;
    top:-8px;
}
.circulo_accepted{
    height: 20px;
    width: 20px;
    background-color: #4752ce;
    border-radius: 50%;
    position: absolute;
    top:-8px;

}
.texto{
    margin-top: 25px;
    text-align: center;
    position: absolute;
}
.texto_accepted{
    font-weight: 500;
    margin-top: 25px;
    text-align: center;
    position: absolute;
}
.fecha{
    /*margin-top: 55px;*/
    text-align: center;
    /*position: absolute;*/
    font-weight: 500;
    color: #999;
}
.linea{
    height: 4px;
    width: 150px;
    border-top: 1px solid #9face0;
    border-bottom: 1px solid #9face0;
    background-color: #f9f9f9;
}
.all>span:last-child>.contenedor>.linea{
    border-right:1px solid #9fa4e0;
}
.all>span:first-child>.contenedor>.linea{
    border-left:1px solid #9fa4e0;
}
.linea_accepted{
    height: 4px;
    width: 150px;
    background-color: #2c2eb4;
}

@media only screen and (max-width: 1080px) {

    .contenedor{
        height: 95px;
    }
    .circulo{
        height: 10px;
        width: 10px;
        top:-3px;
    }
    .circulo_accepted{
        height: 10px;
        width: 10px;
        top:-3px;
    }
    .texto{
        font-size: .7rem;
        margin-top: 15px;
    }
    .texto_accepted{
        font-size: .7rem;
        margin-top: 15px;
    }
    .fecha{
        font-size: .7rem;
        margin-top: 35px;
    }
    .linea{
        height: 4px;
        width: 78px;
    }

    .linea_accepted{
        height: 4px;
        width: 78px;
    }
}
.pdf_style{
    padding:.3rem;
    border: 1px solid rgba(0,0,0,0.3);
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.6);
}
.pdf_style:hover{
    background-color: #f9f9f9;
    box-shadow: 1px 1px 1px rgba(0,0,0,0.4);
    cursor: pointer;
}
.pdf_style:active{
    background-color: #e9e9e9;
    box-shadow: inset 1px 1px 1px rgba(0,0,0,0.4);
    cursor: pointer;
}
@media only screen and (max-width: 420px) {

    .contenedor{
        height: 95px;
    }
    .circulo{
        height: 8px;
        width: 8px;
        top:-2px;
    }
    .circulo_accepted{
        height: 8px;
        width: 8px;
        top:-2px;
    }
    .texto{
        font-size: .6rem;
        margin-top: 13px;
    }
    .texto_accepted{
        font-size: .6rem;
        margin-top: 13px;
    }
    .fecha{
        font-size: .6rem;
        margin-top: 30px;
    }
    .linea{
        height: 3px;
        width: 50px;
    }

    .linea_accepted{
        height: 3px;
        width: 50px;
    }

}