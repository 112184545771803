.justBorders{
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 1.5rem 1.2rem;
    box-shadow: 0 0 3px #bbb ;
}

.selects_input_style{
    margin: 0.3rem 0rem 1.5rem 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}
.grid_container_all{
    width:100%;
    display: grid;
    grid-template-columns: 2fr 4fr;
    grid-gap: 1rem;
}
.grid_container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    /*padding: 10px;*/
    margin: .3rem 0rem .3rem 0;
}
@media only screen and (max-width: 900px) {
    .grid_container {
        grid-template-columns: 1fr 1fr;
    }
    .grid_container_all{
        grid-template-columns: 1fr 1fr;
    }
}
@media only screen and (max-width: 600px) {
    .grid_container {
        grid-template-columns: 1fr 1fr;
    }
    .grid_container_all{
        grid-template-columns: 1fr;
    }
    .justBorders{
        margin: 1rem;
        padding: 1.5rem 0.5rem;
    }
}