@value colores: "../../../../styles/colors.css";
@value primary, primary2 from colores;

.container{
    margin: 0 3vw;
}
.mapa{
    padding: 1rem 0;
}
.titulo{
    padding: 1.5rem 1rem .5rem .5rem;
    font-size: 20px;
    font-weight: 600;
}
.titulo2{
    padding: 0rem 1rem 1rem 1rem;
    font-size: 22px;
    font-weight: 600;
}
.titulo3{
    padding: 0rem 1rem 1rem 3rem;
    font-size: 22px;
    font-weight: 600;
}
.foto_perfil{
    height: 60px;
    width: 60px;
    object-fit: cover;
    border-radius: 50%;
}
.tabla{
    border-collapse: separate;
    border-spacing: 0 15px;
}
.tr_data{
    background-color: #f0f0f5;
    height: 80px;
    border: 4px solid #ddd;
}
.tr_data:hover{
    cursor: pointer;
    background-color: #e6e7ef;
}
.tr_data>td{
    vertical-align: middle;
    border-top: 2px solid #ddd;
}
.tr_data>td:first-child{
    border-left: 1px solid #ddd;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}
.tr_data>td:last-child{
    border-right: 3px solid #ddd;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}


.tr_data>td>span{
    vertical-align: middle;
}
.tr_data_select{
    background-color: #d6d3ee;
    height: 80px;
    border: 1px solid #777;
}
.tr_data_select:hover{
    cursor: pointer;
    background-color: primary2;
}
.tr_data_select>td{
    vertical-align: middle;
    border-top: 2px solid #999;
}
.tr_data_select>td:first-child{
    border-left: 1px solid #aaa;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}
.tr_data_select>td:last-child{
    border-right: 3px solid #777;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}
.rating{
    margin: -7px .4rem 0;
}


.tr_data>td>span{
    vertical-align: middle;
}
.button_div{
    display: flex;
    justify-content: flex-end;
    margin: 2rem 1rem;
}
.button_div2{
    display: flex;
    justify-content: flex-start;
    margin: 2rem 1rem;
}

.button_next{
    padding: .6rem 2rem;
}
.button_prev{
    padding: .6rem 1.6rem;
    margin: 1.5rem 1rem 1rem;
}
.mapa_bajo_inspector{
    border: 1px solid #bbb;
    padding: 1rem;
    margin: 1rem;
    border-radius: 8px;

}
.mapa_bajo_inspector_dos{
    border: 1px solid #bbb;
    /*width: 95%;*/
    padding: 1rem;
    margin: 1rem;
    border-radius: 8px;
}
div:has(> .datos_recibidor_bold){
    width: 80%;
}
.datos_recibidor{
    display: flex;
    border: 1px solid #bbb;
    margin: 1rem;
    padding: .3rem;
    border-radius: 8px;
    min-width: 50%;
    max-width: 500px;
}

.datos_recibidor_bold{
    font-weight: 700;
}
.icono_recibidor{
    display: flex;
    align-items: center;
    font-size: 30px;
    color: primary;
    padding: 0 .7rem;
}
.button_comment{
    margin-top: 1.5rem;
}
.comentarios{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.costo{
    padding-left: 1rem;
}
.costo_items{
    display: flex;
}
.total{
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 20px;
    padding-left: 1rem;
}
.total_buttons{
    display: flex;
    justify-content: space-around;
}
.costo_items_bold{
    padding-left: .5rem;
    font-weight: bold;
}
.timeline_cont{

    position: relative;
    height: 100px;
    width: 1050px;
    display: flex;
    margin: auto;
    overflow-x: hidden;
}
.timeline{
    position: absolute;
    z-index: 1;
    width: 100%;
}

.timeline2{

    position: absolute;
    width: 100%;
    overflow: hidden;
}
.documentos{
    margin:1rem;
    padding: 1rem;
    border:1px solid #bbb;
    border-radius: 7px;
    height: inherit;
}
.documentos_title{
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 1rem;
}

.documentos_link{
    position: relative;
    border: 2px solid #aaa;
    background-color: #eee;
    border-radius: 5px;
    padding: .5rem;
    margin-bottom: 1rem;
    transition: all .2s ease-in-out;
}
.documentos_link p:before{
    content:"Click to copy link ";
    color: transparent;
}
.documentos_link p{
    color: #aaaaaaaa;
}
.documentos_link:hover{
    cursor: pointer;
    background-color: #e4e4e4;
}
.documentos_link:active{
    background-color: #ccc;
}
.documentos_link:active p:before{
    content:"Copied! "
}
.documentos_link:hover p:before{
    color:black;
    background:#ccc;
    border-radius: 3px;
    padding: 2px;
    border: 1px solid #bfbfbf;

}
.documentos_link:hover p    {
    color: #777;
}
.solo_p:hover{
    background: #ccc;
    border: 1px solid #ccc;
    border-radius: 5px;
}


