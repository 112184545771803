.all_tabs{
    margin: 2rem auto 0 auto;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 0.5rem;
    justify-content: center;
    box-shadow: 0 0 3px #bbb ;
    /*background-color: #f9f9f9;*/
}
.tabs{
    border-bottom: #edeefc 2px solid !important;
}
.tabs>li{
    background-color: #fff;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    margin: 0 1px;
    box-shadow: 0px -1px 3px #777;
    color: black;
}
.tabs>li>.active{
    background-color: #ee0101 !important;
}
.tabs>li:hover{
    box-shadow: 0px 0px 2px #777;
    transition: box-shadow 0.2s;
}
.tabs>li>button{
    color: #555;
    border-color: transparent !important;

}


/*
@media only screen and (min-width: 870px) {
    .all_tabs {
        width: 90%;
    }

}
@media only screen and (min-width: 1080px) {
    .all_tabs {
        width: 95%;

    }

}*/