@value colors: "../../styles/colors.css";
@value primary from colors;

.texto_menu{
    font-weight: 400;
    vertical-align: middle;
}
.custom_icon{
    margin: 0 10px 0 5px;
}
.fill_icon{
    fill: primary;
}
.custom_sidebar>div{
    min-height: 100vh!important;
    height: 100% !important;
    /* NO min-height: 100vh!important;*/
    /*height: calc(100vh - 6rem);*/
}
.custom_sidebar *{
    position: sticky;

    font-size: 1.2rem;
}
.custom_menu{
    color: primary;
    padding-top: 0 !important;

}
.custom_menu_item>*:hover {
    background: #cbcae8;
}
.custom_menu_item  span>li>div>span>span{
    /*background: white;*/
    border-width: 0 3px 3px 0 !important;
    stroke: #ddd;
    margin-bottom: 2.2rem;
}
/*.custom_menu_item  span>li{*/
/*    !*background: white;*!*/
/*    color: black !important;*/
/*    stroke: #ddd;*/
/*    margin-bottom: 2rem;*/
/*}*/
.custom_menu_item > *{
    padding: 12px 0px 12px 15px  !important;
    border-bottom: 2px solid #585abb22;
    color : #585abb;
}
.custom_menu_item_customer>*:hover {
    background: #4756d2;
    color: #dbdbdb !important;

}
.custom_menu_item_customer > *{
    background: #8691f6;
    color: #dbdbdb;
    padding: 12px 0px 12px 15px  !important;
    border-bottom: 2px solid #585abb22;
}
.custom_menu_item_surveyor>*:hover {
    background: #5864ce;
    color: #efefef !important;

}
.custom_menu_item_surveyor > *{
    background: #a5aefb;
    color: #efefef;
    padding: 12px 0px 12px 15px  !important;
    border-bottom: 2px solid #585abb22;
}


.custom_menu_item_active >*{
    padding: 12px 0px 12px 15px  !important;
    background-color: primary;
}
.custom_menu_item_active span{
   color: #dddddd;
   stroke: #ddd;
}
.custom_menu_item_active svg path{
    fill: #dddddd;

}
.custom_menu_item_active2 >*{
    padding: 12px 0px 12px 15px  !important;
    background-color: primary;
}

.custom_menu_item_active2 span{
    color: #dddddd;
}

.custom_menu_item_active3 >*{
    padding: 12px 0px 12px 15px  !important;
    background-color: primary;
}
.custom_menu_item_active3 span{
    color: #dddddd;
    stroke: #ddd;
}
.custom_menu_item_active3 span>li>div>span>span{
    /*background: white;*/
    color: black !important;
    border-color: whitesmoke !important;
    stroke: #ddd;
    margin-bottom: 3rem;
    border-width: 0 3px 3px 0 !important;
}

.custom_menu_item_active3>div>span>svg>path{
    fill: transparent;
    stroke: #ddd;
}
.custom_menu_item>div>span>svg>path{
    stroke: #585abb;
}
.custom_item{
    color: #F3F5FF;
}
.custom_menu_item span>li>div {
    padding: 2px 0 3px 0 !important;
    background: transparent;
}
/*------------------*/
.custom_submenu_item>* {
    background: #f1f5fd;
    color: #585abb !important;
    padding-bottom: 0;
}
.custom_submenu_item>*:hover {
    background: #cbcae8;
    padding-bottom: 0;
}
.custom_submenu_item > *{
    padding: 12px 0px 12px 0px  !important;
    border-bottom: 2px solid #585abb22;
}
.custom_submenu_item:last-child {
    padding-bottom: 0;
}
.custom_submenu_item:hover{
    background: #111;
}
.custom_submenu_item_active >*{
    padding: 12px 0px 12px 0px  !important;
    background-color: primary;
}

.custom_submenu_item_active span{
    stroke: #ddd;
}
.custom_submenu_item_active svg path{
    fill: #dddddd;

}
.custom_submenu_item_active2 >*{
    padding: 12px 0px 12px 0px  !important;
    background-color: primary;
}

.custom_submenu_item_active2 span{
    color: white;
}

.custom_menu_item2{
    background: red;
    padding-top: 0 !important;
}
.new_custom_sub > * {
    padding: 12px 0 12px 15px  !important;
    border-bottom: 2px solid #585abb22;
    background: #f1f5fd;
}
.new_custom_sub_invoiced > * {
    padding: 12px 0 12px 15px  !important;
    border-bottom: 2px solid #585abb22;
    background: #dce2ee;
}
.new_custom_sub_invoiced > *:hover {

    background: #cbcae8;
}
.new_custom_sub_invoiced >div>span>span {
    color: #585abb !important;
}
.new_custom_sub > *:hover {
    background: #a7bae3;
}
.new_custom_sub>div>span>svg>path{
    stroke: #585abb;
}




@media only screen and (min-width: 870px) {
    /*.custom_sidebar{*/
    /*    height: 100%;*/
    /*}*/

}
