.all_surveyor{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    min-height: 100vh;
    padding: 1rem 6rem;
}

.title_surveyor{
    display: flex;
    width: 90% ;
    flex-direction: row;
    justify-content: space-between;
}
.title_surveyor>p{
    font-weight: 600;
    font-size: 1.5rem;
}
.title_button{
    height: 2.6rem;
}
.breadcrumb{
    align-self: start;
    margin: .3rem 0 1rem .5rem;
}
.like_a{
    margin:0 5px;
    text-decoration: none;
    color: blue;
    cursor: pointer;
}
.like_a:hover{
    text-decoration: underline;
}
.bold{
    margin:0 5px;
    font-weight: 600;
}

.pagina a{
    margin-bottom: 0;
    padding-bottom: 9px;
}
.pagina_activa a{
    padding-bottom: 19px;

}
.grafico{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.grafico{
    width: 100%;
    height: 100% ;
}
.dashboard{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200%;
}
.loader {

    background: #e48d3dbb;
    width: 6em;
    height: 6em;
    /*transform: translate(-50%, -50%);*/
    text-align: center;

    animation: 3.3s rotate linear infinite;
}
.loader:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #75b629bb;
    animation: 3.2s rotate  linear infinite ;

}
.loader:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #5B69EAbb;
    animation: 2.1s rotate reverse linear infinite ;

}
@keyframes rotate {
    0%{    transform: rotate(0deg)}
    100%{    transform: rotate(180deg)}
}
/*media queries*/

@media only screen and (max-width: 1620px) {
    .all_surveyor{
        padding: 2rem 3rem;
    }
}
@media only screen and (max-width: 1500px) {
    .all_surveyor{
        padding: 2rem 1rem;
    }
}
@media only screen and (max-width: 600px) {
    .all_surveyor{
        padding: 2rem 0;
    }

}
