@value colores: "../../styles/colors.css";
@value primary from colors;

$sidebar-bg-color : #f3f7ff;
$submenu-bg-color : #5961bc;
$highlight-color: #5966bc;
$sidebar-width:230px;
$sidebar-collapsed-width:0px;
$sidebar-color: #595bbc;
$breakpoint-md: 870px;

.pro-sidebar-inner{
  box-shadow: inset -4px 0px 4px -4px #595bbc
}
.pro-inner-item{
    padding-left: 0 !important;
    margin-left: 0 !important;
    box-shadow: inset -4px 0px 4px -4px #595bbc
}



.pro-inner-list-item{
  background: #9898ff;
  padding-left: 0 !important;
  margin-left: 0 !important;
}
@import "react-pro-sidebar/dist/scss/styles.scss";