.loader {
    background: #e48d3dbb;
    width: 3em;
    height: 3em;
    /*transform: translate(-50%, -50%);*/
    text-align: center;
    animation: 3.3s rotate linear infinite;
}
.loader:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #75b629bb;
    animation: 3.2s rotate  linear infinite ;

}
.loader:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #5B69EAbb;
    animation: 2.1s rotate reverse linear infinite ;

}
@keyframes rotate {
    0%{    transform: rotate(0deg)}
    100%{    transform: rotate(180deg)}
}
