.claim_all{
    flex-grow:1;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    width: calc(100vw - 250px);
    padding: 2rem 6rem;
}

.btn_all_claims{
    width: 17ch;
    height: 100%;
    /*background: darkgreen;*/
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 1rem;
    font-weight: bolder;
    color: white;
    border-radius: 5px;
    box-shadow: inset 1px 1px 2px #aaa, 2px 2px 2px #666;
    justify-content: center;
    text-align: center;
    cursor: pointer;
}

.btn_all_claims:hover{
    cursor: pointer;
    /*color: black;*/
    font-weight: 700;
    filter:brightness(.9);
    box-shadow: inset 1px 1px 2px #555, 2px 2px 2px #aaa;
    text-shadow: 1px 1px 1px black ;
    transition: all .2s ease-in-out;
}

.loader {
    background: #e48d3dbb;
    width: 3em;
    height: 3em;
    /*transform: translate(-50%, -50%);*/
    text-align: center;
    animation: 3.3s rotate linear infinite;
}
.loader:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #75b629bb;
    animation: 3.2s rotate  linear infinite ;

}
.loader:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #5B69EAbb;
    animation: 2.1s rotate reverse linear infinite ;

}
@keyframes rotate {
    0%{    transform: rotate(0deg)}
    100%{    transform: rotate(180deg)}
}

@media only screen and (max-width: 1620px) {
    .claim_all{
        padding: 2rem 3rem;
    }
}
@media only screen and (max-width: 1500px) {
    .claim_all{
        padding: 2rem 1rem;
    }
}

@media only screen and (max-width: 530px) {
    .claim_all{
        margin: 0;
    }
}
