@value colors: "../../../styles/colors.css";
@value primary from colors;

.contiene_tabla{
    margin: 3rem 0 ;
    /*border-top: 1px solid #444;*/
    border-collapse: separate;
    justify-content: center;
    width: 90vw;
    border-top: 1px solid #ccc;
    /*border-radius: 6px;*/
    overflow: auto;
}
.mostrar_nuevo_reclamo{
    display: flex;
    justify-content: space-between;
    width: 90vw;
}
.mostrar{
    display: flex;
}
.mostrar p{
    margin-bottom: 0;
    margin-right: 1rem;
    align-self: center;
}
.mostrar>select{
    background-color: #f1f3fe;
    box-shadow: inset 1px 1px 3px #ccc;
}
.mostrar *{
    width: 9ch;
}
.dropdown *{
    color: whitesmoke;
    /*background-color:#5B69EA ;*/
    /*border-radius: 10px;*/
    /*border-color: #5664dc;*/
}
.dropdown button:hover{
    /*background-color: #4d59cb;*/
}
.dropdown button:focus{
    /*background-color: #424db0;*/
}


.th_container_nr{
    min-width: 15ch;
}
.th_buttons{
    min-width: 26ch;
}
.th_buttons *{
    border-color: #5664dc;
    background-color: #424db0;
    margin: 0 0.2rem;
}
.th_buttons *:hover{
    background-color: #4d59cb;
}
.th_buttons *:focus{
    background-color: #424db0;
}
/*.tr_data{*/
/*    border-top: #bbb solid 1px;*/
/*}*/

.tabla{
    border-collapse: separate;
    border-spacing: 0 15px;

}
.tr_data{
    background-color: #f5f5ff;
    height: 60px;
    /*border: 3px solid #ddd;*/
}
.tr_data:hover{
    /*cursor: pointer;*/
    /*background-color: #efefef;*/
}
.tr_data>td{
    vertical-align: middle;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd !important;

}
.tr_data>td:first-child{
    border-left: 1px solid #ddd;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}
.tr_data>td:last-child{
    border-right: 1px solid #ddd;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}


.tr_data>td>span{
    vertical-align: middle;
}
.botones_surveyor{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*height: inherit;*/
    width: 100%;
}
.boton_icono{
    color:blue;
    font-size:17px;
    padding:5px;
    border:#ccc 1px solid;
    border-radius: 5px;
    margin: 0 7px;
}
.botones_botones_surveyor>div>svg {
    justify-self: center;
    margin: 7px;
}
.botones_botones_surveyor>div {
    cursor: pointer;
    background-color: #f6f6f6;
    box-shadow: 1px 1px 1px #aaa;
}
.botones_botones_surveyor>div:hover {
    background-color: #e5efff;
    box-shadow: inset 1px 1px 1px #aaa;
}
.botones_botones_surveyor>div:active {
    background-color: #d3e2ff;
    box-shadow: -1px -1px 1px #aaa;

}
@media only screen and (min-width: 1200px) {
    .contiene_tabla {
        /*width: 70vw;*/
        width: 100%;
    }
    .mostrar_nuevo_reclamo{
        width: 70vw;
    }
}
@media only screen and (max-width: 1400px) {
    .contiene_tabla {
        width: 95%;

        /*width: 100%;*/
    }
    .mostrar_nuevo_reclamo{
        width: 60vw;
    }
}
@media only screen and (max-width: 1245px) {
    .contiene_tabla {
        width: 100%;
        font-size: 14px;

    }
    .tr_data{
        padding: 0;
        margin: 0;
    }

    .th_buttons{
        min-width: 0;
    }
    .th_buttons *{
         margin: .2rem 0;
        font-size: 14px !important;

    }

}
@media only screen and (max-width: 950px) {
    .contiene_tabla {
        font-size: 13px;
    }
}
@media only screen and (max-width: 680px) {
    .contiene_tabla {
        width: calc(100vw - 20px);
    }

}
