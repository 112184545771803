@value fontBig, fontMedium, fontSmall, primary2, primary from '../../../styles/colors.css';


.contain_all_surveyors{
    /*width: 100%;*/
    justify-content: center;
    margin: 1rem !important;
}
.all_surveyors{
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 1.5rem 1.2rem;
    box-shadow: 0 0 3px #bbb ;
}

.selects_input{
    margin: .3rem 0 1.5rem 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}
.selects_input_countryT{
    margin: .3rem 0 1.5rem 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
    border: 1px solid #198653;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
}

input[type=number]{
    -moz-appearance: textfield;
}

.selects_input2{
    margin: 0.3rem 0rem 1.5rem 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}
.selects_input3{
    margin: 0.3rem 0 1.5rem 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
    width: 50% !important;
}
.typeahead{
    padding-top: 0;
    margin: 0 !important;
    display: inline;

    /*width: 20ch;*/
    /*min-width: 200px;*/
    /*flex-direction: column;*/
}
.typeahead input{
    margin-top: 5px;
    display: inline !important;
    box-shadow: inset 1px 1px 3px #ccc;
    background: #f1f3fe;
}
/*.selects_input::before {*/
/*    border: #4d59cb 1px solid;*/
/*    height: 100%;*/
/*    color: blue;*/
/*    display: flex;*/
/*    margin-left: .3rem;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    border-radius: 3px;*/
/*    content: url("../../../styles/icon.svg"); !* texto por defecto *!*/
/*    position: absolute;*/
/*    left: 0;*/
/*}*/
.file_input::file-selector-button {
    /*border-color: transparent;*/
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    color: transparent !important;
    width: 10%;
    height: 100%;
    background: url("../../../styles/icon2.svg") no-repeat center center;
}
.file_input{
    margin: .3rem 0 0 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}
.file_input2{
    margin: .3rem 0 0 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
    padding: 0;
}
.file_input[type=file]{
    margin: .3rem 0 0 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}
.file_input2[type=file]{
    /*color: transparent;*/
    margin: .3rem 0 0 0;
    width: 0;
    font-size: 0px;
    padding-top: 0;
}
.file_input2[type=file]:after{
    font-size: 16px;
    vertical-align: middle;
    margin: 0;
    content: 'Archivo seleccionado';
    color: #000;
    display: inline-block;
    /*padding-left: 10px;*/

    padding-bottom: 0;
}
.button_input{
    margin: .3rem 0 0 0;
    font-size: 16px;
    background-color: #c9c9ea !important;
    color: white;
    box-shadow: inset 1px 1px 3px #e0e4ff;
    border:1px solid #ccc !important;
}
.button_input:hover{
    box-shadow: inset 1px 1px 3px #414186;
    background-color: #6e6ed3 !important;
}

.file_title{
    margin-top: 1.5rem;
}


.file_input::file-selector-button  {
    /*background-color: #eeeeee !important;*/
    margin-bottom: 0 !important;
    padding: 0 !important;
    border-color: transparent;
    border-left: 5px solid transparent;
    color: transparent !important;
    width: 30px;
    height: 150%;
    background: url("../../../styles/icon.svg") no-repeat center center;
}
.file_input2::file-selector-button {
    /*background-color: red !important;*/
    margin-bottom: 0 !important;
    padding: 0 15px !important;
    border-color: transparent;
    border-left: 15px solid transparent;
    color: transparent !important;
    width: 30px;
    height: 150%;
    background: url("../../../styles/icon.svg") no-repeat center center;
}


.form_control{
    background-color: #4d59cb;
}
.icon_container{
    /*height:100%!important;*/
    padding:0 !important;
    align-self:baseline !important;
    margin-top:.3rem !important;
    background-color:#d9dcea !important;
    border:2px solid #86a3e8 !important;
    /*border-radius: 10px;*/
    box-shadow: inset 2px 2px 4px  #fff;

}
.icon_container:hover{
    background-color: #b4c5ee;
    box-shadow: inset 2px 2px 4px #7a85e5;
    border:2px solid #86a3e8;
}
.icon_container:focus{
    background-color: #a3b8ea;
    box-shadow: inset 2px 2px 4px  #fff;
    border:2px solid #86a3e8;
}
.icon_new_location{
    color: #4d59cb;
    font-size: 1.8rem;
    margin: 0 1rem;
    padding: 0;
    height: 34px !important;


}
.title{
    font-size: fontMedium;
    font-weight: 600;
    margin-bottom: 1rem;
}
.title_surveyor{
    display: flex;
    width: 100% ;
    margin: 0 0 0 1rem;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
}

.title_surveyor>div{
    font-size: fontBig;
    margin-bottom: .7rem;
}
.profile_and_title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}
.image{
    background-color: #e7e9ff;
    border-radius: 10px;
    box-shadow: inset 2px 2px 10px 2px #fff, 1px 1px 5px #888;
    cursor: pointer;
}
.imageB{
    background-color: #e7e9ff;
    border-radius: 10px;
    border: 2px solid #a00;
    box-shadow: inset 2px 2px 10px 2px #fff, 1px 1px 5px #888;
    cursor: pointer;
}
.image2{
    background-color: #e7e9ff;
    border-radius: 10px;
    border: 1px solid #ccc;
    /*box-shadow: inset 2px 2px 10px 2px #fff, 1px 1px 5px #888;*/
}

.image2>img{
    height: 100px;
    width: 100px;
    border-radius: 10px;
    padding: 5px;
    object-fit: cover;
}
.image:hover{
    background-color: #e7e9ff;
    box-shadow: inset 2px 2px 5px  #aaa, 1px 1px 2px #888;
}
.imageB:hover{
    background-color: #e7e9ff;
    box-shadow: inset 2px 2px 5px  #aaa, 1px 1px 2px #888;
}

.imageB>img{
    height: 100px;
    width: 100px;
    border-radius: 10px;
    padding: 5px;
    object-fit: cover;
}
.image>img{
    height: 100px;
    width: 100px;
    border-radius: 10px;
    padding: 5px;
    object-fit: cover;
}
.errors{

    color: purple;
    font-size: 13px;
    margin: 0;
    padding: 0;
}
.errorsT{
    background-color: lightpink;
    color: black;
    font-size: 12px;
    margin: 0;
    padding: 0;
}
