.container{
    margin: 1.5rem 0 0 0;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 1.5rem 1.2rem;
    box-shadow: 0 0 3px #bbb ;
}
.container2{
    margin: 0.5rem;
    border:2px solid #ddd;
    border-radius: 10px;
    margin-bottom: 5px;
    /* height: 100%; */
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 3px #bbb ;
}
.download_icon{
    color:#555;
    font-size:20px;
    vertical-align:text-bottom;
}

.container2>div>div{
margin: 1rem;
}
/* .container2>div>div>div>div>div>button{
    background-color: #eee;
}
.container2>div>div>div>div>div>button:hover{
    background-color: #b5b9c5;
} */

.title_main{
    font-size: 22px;
    padding: 1rem 2rem 0 2rem;
}
.title{
    font-size: 19px;
    padding:.5rem 0;
}
.container>div>div{
    margin: 1rem 4rem;
}

@value colors: "../../../../styles/colors.css";
@value primary, primary2 from colors;
.selects{
    display: flex;
    flex-direction: row;
    align-items:center;
}
.selects_file{
    margin: 0;
}

.file_input{
    margin: .3rem 0 1.5rem 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}

.file_input::file-selector-button  {
    /*background-color: #eeeeee !important;*/
    margin-bottom: 0 !important;
    padding: 0 15px 0 0 !important;

    border-color: transparent;
    border-left: 12px solid transparent;
    border-right: 10px solid transparent;
    color: transparent !important;
    width: 50px;
    height: 150%;
    background: url("../../../../styles/icon.svg") no-repeat center center;
}
.file_input2{
    margin: .3rem 0 0 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
    padding: 0;
}

.button_des{
    display: inline-flex;
    padding: 8px;
    align-self: center;
    vertical-align: center;
    border-radius: 6px;
    background-color: #e0e2f1;
    margin-left: 1rem;
    border: 1px solid #aaa;
    box-shadow: 1px 1px 2px #c7c7c7dd, inset 2px 2px 3px #fff;
    cursor: pointer;
}
.button_des:hover{
    background-color: #ccd0dc;
    box-shadow: inset 1px 1px 2px #666;

}
.file_input2[type=file]{
    /*color: transparent;*/
    margin: .5rem 0;
    padding: .3rem;
    width: 300px;
    height: 35px;
    line-height: 1rem;
    font-size: 0;
    box-shadow: 1px 1px 3px #aaa;
    /*border: #2c2eb4 4px solid;*/

}
.file_input2::file-selector-button {
    /*background-color: red !important;*/

    margin-bottom: 0 !important;
    padding: 0 15px 0 0 !important;

    border-color: transparent;
    border-left: 12px solid transparent;
    border-right: 5px solid transparent;
    color: transparent !important;
    width: 55px;
    height: 150%;
    background: url("../../../../styles/icon.svg") no-repeat center center;

}
.file_input2[type=file]:after{
    font-size: 16px;

    vertical-align: middle;
    margin: 0;
    content: 'Sobreescribir archivo';
    color: #000;
    line-height: 20px;
    display: inline-block;
    /*padding-left: 10px;*/
    padding-bottom: 0;
}
.file_input2[type=file]:hover{
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;

}
.addicon {
    /*background-color: #eeeeee !important;*/
    /* border-color: transparent; */
    /* border-left: 5px solid transparent; */
    color: blue;
    width: 16%;
    /* height: 100%; */

    margin-right: 0.3rem;
    background: url("../../../../styles/icon3.svg") no-repeat center center;
}
.removeicon {
    color: red;
    width: 16%;
    margin: 0.3rem;
    background: url("../../../../styles/icon4.svg") no-repeat center center;
}
.buttonadd {
    display: inline-block;
    border: 2px #0000ff66 solid;
    /*background-color: #00006606;*/
    border-radius: 5px;
    box-shadow: 1px 1px #aaa;
    padding: .5rem 1rem;
    min-width: 10ch;
    margin: 0.3rem;
    cursor: pointer;
}
.buttonremove {
    display: inline-block;
    border: 2px #ff000066 solid;
    /*background-color: #ff000006;*/
    border-radius: 5px;
    box-shadow: 1px 1px #aaa;
    padding: .5rem 1rem;
    min-width: 10ch;
    margin: 0.3rem;
    cursor: pointer;
}
.selects_input{
    min-width: 10ch;
    margin: .3rem 0 1.5rem 0;
    font-size: 16px;
    background-color: #f1f3fe !important;
    box-shadow: inset 1px 1px 3px #ccc;
}
.selects_input_disabled{
    min-width: 10ch;
    margin: .3rem 0 1.5rem 0;
    font-size: 16px;
    color: #525252 !important;
    background-color: #f5f5f5 !important;
    box-shadow: inset 1px 1px 0px #ccc;
}


.titles_p{
    margin-top: 1rem;
    margin-left: 1rem;
}

.inputIntercambios {
    display: flex;
    flex-direction: row;
    margin: 1rem;
}

@media only screen and (max-width: 1205px) {
    .columns{
        display: inline;
        padding:0;
    }
    .selects_mini>div{
        align-self: center;
    }
    /*p{*/
    /*    align-self: center;*/
    /*}*/
    .selects_p{
        align-self: self-start;
    }

}
@media only screen and (max-width: 605px) {
    .row>div:first-child{
        min-width: 100%;
    }
}
@media only screen and (min-width: 605px) {
    .selects{
        max-width: 30vw;
    }
}

@media only screen and (max-width: 605px) {
    .inputIntercambios{
        max-width: 30vw;
        flex-direction: column;
    }
}


