.all{
    height: 100vh;
    max-height: -webkit-fill-available
}
.fila {
    --bs-gutter-x: 0 !important;

    position:absolute;
    bottom: 0;
    left:0;
    right:0;
    height:20px;
    width: 100vw;
}
.card {
    /*padding-bottom: 3rem;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;

    justify-content: space-around;
    background-color: white;
    width: 20%;
    height: 80%;
    max-height: 27vw;
    margin: 5vw;
    border: solid 3px #999;
    border-radius: 10px;
    box-shadow: 4px 4px 12px #bbb;

}
.card img{
    overflow: hidden;
}

.card:hover{
    box-shadow: 1px 1px 1px #ccc;
    cursor: pointer;
}
.cardCont{
    height:50%;
    display:flex;
    justify-content: center;
}

@media only screen and (max-width: 1200px) {
    .card {
        width: 30vw;
        margin: 1rem;
    }
    .cardCont{
        justify-content: space-around;
    }
}
@media only screen and (max-width: 787px) {
    .card {
        flex-direction: column;
        align-self: center;
        height: 30%;
        width: 20%;
        min-width: 200px;
        margin: 5px;
        padding: 0;
    }
    .card img{
        display: none;
    }
    .cardCont{
        flex-direction: column;
    }
}