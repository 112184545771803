body *{
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  font-family: 'Hind', sans-serif;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: calc(12px + (17 - 13) * ((100vw - 300px) / (1600 - 360)));
}

code {
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
  /*  monospace;*/
}

.allbtn{
  width: 8rem;
  height: 100%;
  background-color: whitesmoke;
  font-size: small;
  font-weight: bolder;
  line-height: normal;
  padding: 8px 0;
  color: black;
  border-radius: 5px;
  border:none;
}
.surveybtn{
  width: 8rem;
  height: 100%;
  background-color: darkgreen!important;
  font-size: small;
  font-weight: bolder;
  line-height: normal;
  padding: 8px 0;
  color: white;
  border-radius: 5px;
  border:none;
}
.recoverybtn{
  width: 8rem;
  height: 100%;
  background-color: rebeccapurple;
  font-size: small;
  font-weight: bolder;
  line-height: normal;
  padding: 8px 0;
  color: white;
  border-radius: 5px;
  border:none;
}

@media (min-width: 870px) {
  .div-btn {
    padding: 0 18%;
  }
}
@media (min-width: 990px) {
  .div-btn {
    padding: 0 4%;
  }
}
