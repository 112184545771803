@value colors: '../../styles/colors.css' ;
@value primary,primary2 from colors;
.login_page{
  padding:0;
  margin:0;
  background:rgba(255,255,255,.4);
  height:100vh;
  display:flex;
  flex-direction: column;
  align-items:center;
  justify-content: center;

}
.captcha{
  padding-top: 5px;
  width: 50vw;
  max-width: 400px;
  color: #888;
  font-size: .7rem;
}
.imagen_logo{
  max-height: 20vh;
  max-width:30vw;
  width: auto;
  margin: 4vh 0 2vh;
}
.welcome{
  margin-bottom:4vh;
  color: #5B5B5B;
  font-size:25px;
  font-weight: 700;
}
.login_box{
  position:relative;
  /*height:70vh;*/
  /*width:50vw;*/
  border:#D8DCFF solid 2px;
  /*box-shadow:1px 1px 1px #bbb;*/
  border-radius:10px;
  background-color:#fff;
  align-items: center;
  justify-content: center;
}
.login_box_inside{
  padding: 2rem;
  /*min-height:200px;*/
  display:flex;
  flex-direction:column;
  justify-content:space-around;
  align-items:center;
}

.input_icon>span{
  background-color: #FAFAFA;
  border-right: none;
  padding: 0 12px;
  color: #9E9E9E;
}
.input_icon>span>svg{
  font-size: 20px;
  color: #9E9E9E77;
}
.form{
  background-color: transparent;
  /*border-left: none;*/
}
.form_label{
  width: 25vw;
  max-width: 400px;
  font-weight: 500;
  color: #5B5B5B;
}
.form_control{
  background-color: #FAFAFA !important;
  border-left:none !important;
}
.boton{
  margin: 2rem 0 0;
  background:#D8DCFF !important;
  color:#5B69EA !important;
  border:none !important;
  padding: .5rem 2.5rem !important;
}
.botonA{
  margin: 2rem 0 0;
  background:#D8DCFF;
  color:snow;
  position: absolute;
  bottom: 30px;
  left: 10px;
}
.olvido_pass{
  color:#5B69EA;
  margin: 2vh 0 6vh;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
}
.olvido_pass:hover{
  text-decoration: underline;
}
.modalContent{
  width: fit-content;
  height: 90vh;
}
div:has(> .modalContent) {
  justify-content: center;
}
.fila {
  --bs-gutter-x: 0 !important;
  position:absolute;
  bottom: 0;
  left:0;
  right:0;
  height:20px;
  width: 100vw;
}
.lang{
  position: absolute;
  top:20px;
  right: 25px;
  padding: 5px;
  background-color: primary2;
  border: 1px solid primary2;
  border-radius: 5px;
}
.lang>select{
  border: #b5bbf3 solid 3px;
  border-radius: 10px;
}
@media (max-width:400px){
  .captcha{
    width: 100%;
  }
  .input_icon{
    width: 70vw !important;
    justify-content: center;
    margin: auto;
  }
}